import React, { useState, useContext  } from 'react';
import { AuthContext } from '../AuthContext';
import { TextField, Button, Box, Typography, Link, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login() {
    const { loginUser, loading, errorMessage, successMessage } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (username === '' || password === '') {
            console.log('Please fill in all fields');
            return;
        }
    
        console.log('Submitting login form');
        const success = await loginUser(username, password);
    
        if (success) {
            console.log('Login successful, navigating to /home');
            navigate('/');  // Navigating to /home if login is successful
        } else {
            console.log('Login failed, stay on the login page');
        }
    };

    return (
        <Box
            className="login-container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '88vh',
                padding: 2,
                backgroundColor: '#f4f6f8',  // Light background for the page
            }}
        >
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
                    maxWidth: '400px',
                    width: '100%',
                }}
            >
                <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    required
                    error={!!errorMessage && !username}  // Show error if empty and form is submitted
                    helperText={!!errorMessage && !username ? errorMessage : ''}
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    error={!!errorMessage && !password}  // Show error if empty and form is submitted
                    helperText={!!errorMessage && !password ? errorMessage : ''}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    {loading ? <CircularProgress size={24} /> : 'Login'}
                </Button>

                <Typography sx={{ marginTop: 2, size: 8, color: 'black' }}>
                    If you are not subscribed, click here to{' '}
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate('/register')} // Use navigate instead of history.push
                    >
                        Register
                    </Link>.
                </Typography>
                <Typography sx={{ marginTop: 2, color: 'black' }}>
                    Forgot your password? Click here to{' '}
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate('/forgot_password')} // Navigate to the "forgot password" page
                    >
                        reset your password
                    </Link>.
                </Typography>
            </Box>
            {errorMessage && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                    {errorMessage}
                </Typography>
            )}
            {successMessage && (
                <Typography color="success" sx={{ marginTop: 2 }}>
                    {successMessage}
                </Typography>
            )}

        </Box>
    );
}

export default Login;