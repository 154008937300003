import React, { useState } from 'react';
import { Button, TextField, Box, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const Klubi = () => {
    const [link, setLink] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState('sq'); // Default language set to Shqip
    const [videoId, setVideoId] = useState('');
    const [year, setYear] = useState(''); // New state for the year

    const handleSubmit = async () => {
        setIsLoading(true);
        setResult('');

        const youtubeVideoId = link.split('v=')[1];
        if (youtubeVideoId) {
            setVideoId(youtubeVideoId.split('&')[0]);
        } else {
            setResult('Linku i YouTube nuk eshte valid...');
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch('https://www.ad-lux.com/api/trans', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ link, language, year }), // Include year in the request body
            });

            if (!response.ok) {
                throw new Error('Rrjeti deshtoi. Ju lutem provoni serish me vone.');
            }

            const data = await response.json();
            setResult(data.response);
        } catch (error) {
            console.error('Error:', error);
            setResult(error.message || 'Nje gabim ka ndodhe pergjate procesimit te linkut...');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            className="klubi-container"
            sx={{
                paddingTop: {
                    xs: '80px',
                    sm: '0px',
                },
                padding: 2,
            }}
        >
            <Typography variant="h6" component="h1" gutterBottom>
                Permbledhje e YouTube videos
            </Typography>

            <Box
                className="tools_container"
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                    mb: 2,
                    padding: 2,
                    border: 1,
                }}>

                <TextField
                    value={link}
                    onChange={(e) => {
                        let inputLink = e.target.value;
                        if (inputLink.includes("youtu.be/")) {
                            const videoId = inputLink.split("youtu.be/")[1];
                            inputLink = `https://www.youtube.com/watch?v=${videoId}`;
                        }
                        setLink(inputLink);
                    }}
                    placeholder="Vendose nje link ketu..."
                    fullWidth
                    sx={{
                        flexGrow: 1,
                        backgroundColor: 'white',
                        input: { color: 'black' },
                    }}
                />

                <FormControl sx={{ minWidth: { xs: '100%', sm: 150 } }}>
                    <InputLabel id="language-label">Gjuha e permbledhjes:</InputLabel>
                    <Select
                        labelId="language-label"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        label="Zgjedh Gjuhen e permbledhjes:"
                    >
                        <MenuItem value="sq">Shqip</MenuItem>
                        <MenuItem value="de">Gjermanisht</MenuItem>
                        <MenuItem value="en">Anglisht</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    placeholder="Viti"
                    type="number"
                    sx={{
                        minWidth: { xs: '100%', sm: 100 },
                        backgroundColor: 'white',
                        input: { color: 'black' },
                    }}
                />

                <Button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    variant="contained"
                    fullWidth
                    sx={{ whiteSpace: 'nowrap', mt: { xs: 1, sm: 0 } }}
                >
                    {isLoading ? 'Procesim...' : 'Proceso'}
                </Button>
            </Box>

            <Box
                className="display-container"
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column', // When the screen is extra small, make it a column
                        sm: 'row',    // When the screen is small or larger, make it a row
                    },
                    gap: 2,
                    border: 1,
                    padding: 2,
                }}
            >
                {/* Div holding the video or placeholder */}
                <Box
                    className="display-video"
                    sx={{
                        flex: 1,
                        minHeight: {
                            xs: '200px',
                            sm: '455px',
                        },
                        height: {
                            xs: '200px',
                            sm: '455px',
                        },
                        overflow: 'hidden',
                        backgroundColor: videoId ? 'transparent' : '#e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {videoId ? (
                        <iframe
                            width="100%"
                            height="100%"
                            style={{
                                border: 'none',
                                boxSizing: 'border-box',
                            }}
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Vendmbajtës i videos (asnjë video e ngarkuar)
                        </Typography>
                    )}
                </Box>

                {/* Div holding the text content */}
                <Box className="display-text"
                    sx={{
                        flex: 1,
                        minHeight: {
                            xs: '200px',
                            sm: '455px',
                        },
                        maxHeight: '455px', // Max height for content div
                        overflowY: 'auto', // Allows scrolling
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                            minheight: {
                                xs: '200px',
                                sm: '455px',
                            },
                            whiteSpace: 'pre-wrap', // Preserve newlines
                            wordWrap: 'break-word', // Break long words
                            backgroundColor: '#f0f0f0', // Set the background color here
                            color: '#333', // Optional: Text color to contrast with the background
                            padding: '10px', // Optional: Add some padding inside the div
                        }}
                    >
                        {result || 'Rezultatet do te shfaqen ketu...'}
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default Klubi;
