import React, { useState, useRef } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import './Translate.css'; // Import custom CSS

const Translate = () => {
    const [inputText, setInputText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState('sq'); // Default language set to Shqip
    const [result, setResult] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);

    const baseURL = 'https://www.ad-lux.com';

    const handleProceed = async () => {
        if (!inputText.trim()) return;

        setIsLoading(true);
        setTranslatedText('');
        setResult('');
        setAudioUrl(null);

        try {
            const response = await fetch('https://www.ad-lux.com/api/translate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ inputText, language }),
            });

            if (!response.ok) {
                throw new Error('Rrjeti deshtoi. Ju lutem provoni serish me vone.');
            }

            const data = await response.json();
            setTranslatedText(data.response);
            if (data.wave_url) {
                setAudioUrl(`${baseURL}${data.wave_url}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setResult(error.message || 'Nje gabim ka ndodhe pergjate procesimit te perkthimit...');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    const handleSpeech = () => {
        if (!translatedText.trim() && !audioUrl) return;

        const audioRef = null;
        if (!isSpeaking) {
            if (audioUrl) {
                const audio = new Audio(audioUrl);
                audio.play();
                setIsSpeaking(true);

                audio.onended = () => setIsSpeaking(false);
                audio.onerror = () => setIsSpeaking(false);
                audioRef.current = audio;
            } else {
                const utterance = new SpeechSynthesisUtterance(translatedText);
                utterance.lang = language;

                utterance.onend = () => setIsSpeaking(false);
                utterance.onerror = () => setIsSpeaking(false);

                utteranceRef.current = utterance;
                window.speechSynthesis.speak(utterance);
                setIsSpeaking(true);
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
        }
    };

    const handleCopy = () => {
        if (!translatedText.trim()) return;

        navigator.clipboard.writeText(translatedText)
            .then(() => {
                alert('Text copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleInput = (e) => {
        setInputText(e.target.value);
    };

    return (
        <Box className="translate-container">
            <Box className="translate-card translate-input-box">
                <Box className="language-select-form">
                    <div
                        className="lang-button"
                    >
                        Autodetect
                    </div>
                </Box>
                <Box className="translation-input">
                    <textarea
                        value={inputText}
                        onChange={handleInput}
                        className="translate-input-field"
                        placeholder="Enter text to translate..."
                        rows={5}
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '4px',
                            overflowY: 'auto', // Changed to auto to allow scrolling
                            resize: 'vertical', // Allow resizing vertically
                        }}
                    />
                </Box>
                <Box className="translate-action-buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProceed}
                        disabled={isLoading || !inputText.trim()}
                        className="translate-button"
                    >
                        {isLoading ? 'Translating...' : 'Proceed'}
                    </Button>
                </Box>
            </Box>

            <Box className="translate-card translate-output-box">
                <Box className="language-select-form">
                    <div
                        className={`lang-button ${language === 'sq' ? 'active' : ''}`}
                        onClick={() => handleLanguageChange('sq')}
                    >
                        Albanian
                    </div>
                    <div
                        className={`lang-button ${language === 'en' ? 'active' : ''}`}
                        onClick={() => handleLanguageChange('en')}
                    >
                        English
                    </div>
                    <div
                        className={`lang-button ${language === 'de' ? 'active' : ''}`}
                        onClick={() => handleLanguageChange('de')}
                    >
                        German
                    </div>
                </Box>

                <Box className="translation-output">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <textarea
                            value={translatedText || result || 'Translation will appear here...'}
                            readOnly
                            className="translation-text"
                            rows={5}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '4px',
                                overflowY: 'auto', // Changed to auto to allow scrolling
                                resize: 'vertical', // Allow resizing vertically
                            }}
                        />
                    )}
                </Box>

                <Box className="translate-action-buttons">
                    <Button
                        variant="outlined"
                        onClick={handleCopy}
                        disabled={!translatedText.trim()}
                        startIcon={<ContentCopyIcon />}
                    />
                    <Button
                        variant="outlined"
                        onClick={handleSpeech}
                        disabled={!translatedText.trim()}
                        startIcon={isSpeaking ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Translate;