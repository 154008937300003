import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import XRangeModule from "highcharts/modules/xrange";
import boost from 'highcharts/modules/boost'; // Import boost module
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faUser, faCoins, faClock, faGlobe } from '@fortawesome/free-solid-svg-icons';
import './News.css';

// Initialize the boost module
boost(Highcharts);
XRangeModule(Highcharts);
HighchartsMore(Highcharts);

const CoinList = () => {
    const [coins, setCoins] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.post('/api/news', { input: "fetch_coins_data" })
            .then(response => {
                setCoins(response.data.coins);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the coins data!", error);
                setLoading(false);
            });
    }, []);

    // Chart component with logging to check data passed to the chart
    const ChartComponent = ({ data, title, color, type = 'area' }) => {
        console.log(`Rendering chart for ${title} with data:`, data); // Log chart data

        const options = {
            chart: {
                type: type, // Dynamic chart type
                height: '250px',
                zoomType: 'x'
            },
            title: {
                text: title,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333'
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%H:%M}',
                    rotation: -45,
                    align: 'right'
                }
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 2
            },
            plotOptions: {
                line: {
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                },
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.color(color).setOpacity(0.6).get('rgba')],
                            [1, Highcharts.color(color).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            series: [{
                name: title,
                data: data,
                color: color,
                turboThreshold: 0
            }],
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            boost: {
                enabled: true,
                useGPUTranslations: true,
                usePreAllocated: true
            }
        };

        return (
            <div className="chart-wrapper">
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        );
    };

    // Log the stats data for each coin
    return (
        <div className="container mx-auto px-4">
            {/*  <h1 className="text-3xl font-bold my-8 text-center"></h1> */}
            {loading ? (
                <div className="loader-container">
                    <ClipLoader color="#4A90E2" loading={loading} size={80} />
                </div>
            ) : (
                coins.length === 0 ? (
                    <p className="text-center text-gray-600"></p>
                ) : (
                    coins.map((coin, index) => {
                        console.log(`Coin ${coin.name} stats:`, coin.stats); // Log coin stats

                        return (
                            <div key={index} className="coin-card">
                                <div className="coin-header">
                                    <div className="coin-info">
                                        <h2 className="coin-title">{coin.name}</h2>
                                        <img
                                            src={`https://ethplorer.io/${coin.image}`}
                                            alt={`${coin.name} logo`}
                                            className="coin-image"
                                        />
                                    </div>

                                    <div className="coin-icons">
                                        <div className="icon-with-tooltip">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            <span className="tooltip">Address: {coin.address}</span>
                                        </div>
                                        <div className="icon-with-tooltip">
                                            <FontAwesomeIcon icon={faUser} />
                                            <span className="tooltip">Owner: {coin.owner}</span>
                                        </div>
                                        <div className="icon-with-tooltip">
                                            <FontAwesomeIcon icon={faCoins} />
                                            <span className="tooltip">Total Supply: {coin.total_supply}</span>
                                        </div>
                                        <div className="icon-with-tooltip">
                                            <FontAwesomeIcon icon={faClock} />
                                            <span className="tooltip">Last Updated: {new Date(coin.last_updated).toLocaleString()}</span>
                                        </div>
                                        <div className="icon-with-tooltip">
                                            <FontAwesomeIcon icon={faGlobe} onClick={() => window.open(coin.website, '_blank')} />
                                            <span className="tooltip">Website: <a href={coin.website} target="_blank" rel="noopener noreferrer">{coin.website}</a></span>
                                        </div>
                                    </div>
                                </div>

                                {/* Add the line here */}
                                <div className="coin-header-line"></div>

                                {coin.stats && coin.stats.length > 0 && (
                                    <div className="stats-section">
                                        <div className="chart-container">
                                            {/* First Chart - Line Chart for Price Rate */}
                                            <ChartComponent
                                                data={coin.stats.map(stat => [new Date(stat.ta).getTime(), stat.price_rate || 0])}
                                                title="Price Rate"
                                                color="#8884d8"
                                                type="spline" // Line chart for price rate
                                            />

                                            {/* Other Charts - Area Charts */}
                                            <ChartComponent
                                                data={coin.stats.map(stat => [new Date(stat.ta).getTime(), stat.volume_24h || 0])}
                                                title="Volume 24h"
                                                color="#82ca9d"
                                                type="column"
                                            />
                                            <ChartComponent
                                                data={coin.stats.map(stat => [new Date(stat.ta).getTime(), stat.transfers_count || 0])}
                                                title="Transfers Count"
                                                color="#ffc658"
                                                type="scatter"
                                            />
                                            <ChartComponent
                                                data={coin.stats.map(stat => [new Date(stat.ta).getTime(), stat.holders_count || 0])}
                                                title="Holders Count"
                                                color="#ff7300"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })
                )
            )}
        </div>
    );
};

export default CoinList;