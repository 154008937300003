import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Ballina from './components/pages/Ballina';
import GenAi from './components/pages/GenAi';
import FlightTrack from './components/pages/FlightTrack';
import Klubi from './components/pages/Klubi';
import Translate from './components/pages/Translate';
import News from './components/pages/News';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Register from './components/Register';  // Import Register
import Login from './components/Login';        // Import Login
import { AuthProvider } from './AuthContext';  // Wrap the app with AuthProvider
//import ProtectedRoute from './components/ProtectedRoute'; // Import your ProtectedRoute
import ForgotPassword from './components/ForgotPassword';
import Profile from './components/UserProfile'; // Import the new Profile component
import ChatBot from './components/ChatBot';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',  // Blue as the primary color
    },
    secondary: {
      main: '#d32f2f',  // Red as the secondary color
    },
    background: {
      default: '#f5f5f5',  // Light background
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ballina" element={
                <Ballina />
            } />
            <Route path="/news" element={
              <News />
            } />
            <Route path="/genai" element={
                <GenAi />
            } />
            <Route path="/klubi" element={
                <Klubi />
            } />
            <Route path="/flighttrack" element={
                <FlightTrack />
            } />
            <Route path="/profile" element={
                <Profile />
            } />
            <Route path="/translate" element={<Translate />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />

          </Routes>
        </Router>
      </AuthProvider>
      <ChatBot />
    </ThemeProvider>
  );
}

export default App;
