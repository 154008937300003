import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Define an icon for the planes
const planeIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/484/484167.png',
  iconSize: [25, 25],
});

// Dummy fallback for testing if the API is not working
const fallbackFlights = [
  { latitude: 42.6, longitude: 21.1, callsign: 'Flight 1', origin_country: 'Country A' },
  { latitude: 42.7, longitude: 21.2, callsign: 'Flight 2', origin_country: 'Country B' }
];

const FlightsMap = () => {
  const [flights, setFlights] = useState([]);

  // Fetch flights within the current map bounds
  const fetchFlightsInBounds = useCallback(async (bounds) => {
    try {
      const response = await fetch('https://www.ad-lux.com/api/flighttrack');
      const data = await response.json();

      // Filter flights to include only those within the map bounds
      const flightsInBounds = data.filter((flight) => {
        const flightLatLng = L.latLng(flight.latitude, flight.longitude);
        return bounds.contains(flightLatLng);
      });

      setFlights(flightsInBounds);
    } catch (error) {
      console.error('Error fetching flight data:', error);
      setFlights(fallbackFlights); // Use fallback data if API fails
    }
  }, []); // Empty dependency array as it doesn't use any external values

  // Custom hook to handle fetching flight data
  const MapEventHandler = () => {
    const map = useMap();

    useEffect(() => {
      const handleMapChange = () => {
        const bounds = map.getBounds();
        fetchFlightsInBounds(bounds);
      };

      handleMapChange(); // Initial fetch when the map loads

      // Fetch data on map move
      map.on('moveend', handleMapChange);

      // Set interval to fetch data every 10 seconds
      const intervalId = setInterval(handleMapChange, 10000);

      // Cleanup event listener and interval on unmount
      return () => {
        map.off('moveend', handleMapChange);
        clearInterval(intervalId);
      };
    }, [map]); // Remove fetchFlightsInBounds from the dependency array

    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '300px',
          maxHeight: '300px',
          border: '2px solid #000',
          boxSizing: 'border-box',
        }}
      >
        <MapContainer
          center={[42.5728, 21.0353]}
          zoom={8}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />

          <MapEventHandler />

          {flights.map((flight, idx) => (
            <Marker
              key={`${flight.callsign}-${idx}`}
              position={[flight.latitude, flight.longitude]}
              icon={planeIcon}
            >
              <Popup>
                {flight.callsign} <br /> {flight.origin_country}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default FlightsMap;