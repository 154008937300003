import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Register() {
    const { registerUser, checkUsernameAvailability } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleUsernameBlur = async () => {
        if (username) {
            try {
                const isAvailable = await checkUsernameAvailability(username);
                if (!isAvailable) {
                    setUsernameError('Emri i përdoruesit është tashmë i zënë. Ju lutem zgjidhni një tjetër.');
                } else {
                    setUsernameError('');
                }
            } catch (error) {
                console.error('Error checking username availability:', error);
                setUsernameError('Error checking username availability');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (username === '' || password === '' || email === '') {
            setErrorMessage('Please fill in all fields');
            return;
        }

        if (usernameError) {
            setErrorMessage('Please fix the errors before submitting');
            return;
        }

        setErrorMessage('');

        try {
            await registerUser({ username, password, email });
            setSuccessMessage('Registration successful! Redirecting to login...');
            setUsername('');
            setPassword('');
            setEmail('');

            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            setErrorMessage(error.message || 'Registration failed. Please try again.');
        }
    };

    return (
        <Box
            className="register-container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '88vh',
                paddingTop: {
                    xs: '80px',  // Add padding-top only on extra small screens (e.g., mobile)
                    sm: '0px',    // No padding on small and larger screens
                },
                padding: 2,
                backgroundColor: '#f4f6f8',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Register
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
                    maxWidth: '400px',
                    width: '100%',
                }}
            >
                <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={handleUsernameBlur} // Check username on blur
                    fullWidth
                    required
                    error={!!usernameError} // Display error if username is taken
                    helperText={usernameError || ''}
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    error={!!errorMessage && !password}
                    helperText={!!errorMessage && !password ? errorMessage : ''}
                />
                <TextField
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    error={!!errorMessage && !email}
                    helperText={!!errorMessage && !email ? errorMessage : ''}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    Register
                </Button>
            </Box>

            {/* Show error message if any */}
            {!!errorMessage && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                    {errorMessage}
                </Typography>
            )}

            {/* Show success message if registration is successful */}
            {!!successMessage && (
                <Typography color="primary" sx={{ marginTop: 2 }}>
                    {successMessage}
                </Typography>
            )}

            <Typography sx={{ marginTop: 2, color: 'black'  }}>
                Already have an account? Click here to{' '}
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate('/login')}
                >
                    Login
                </Link>.
            </Typography>
        </Box>
    );
}

export default Register;