import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ResizeObserver from 'resize-observer-polyfill';

// Ensure the polyfill is applied globally if ResizeObserver is not available
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
