import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//import { TryRounded } from '@mui/icons-material';

const ChatWindow = ({ isOpen, messages, inputMessage, setInputMessage, handleSend, isLoading, closeChatWindow }) => {
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        if (isOpen && messages.length > 0) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isOpen, messages.length]);

    if (!isOpen) return null;  // If chat window is not open, don't render anything

    return (
        <Box
            sx={{
                width: '300px',
                height: '500px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderRadius: '0px',
                border: '2px solid #D8D8D8',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                position: 'fixed',
                bottom: '100px',
                right: '1rem',
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100px',
                    padding: 2,
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/chat_banner.png'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderBottom: '1px solid #e0e0e0',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: '0.95rem', fontWeight: 'bold', color: 'black' }}
                >
                    GJYLA CHATBOT
                </Typography>
                <IconButton
                    onClick={closeChatWindow}
                    sx={{
                        backgroundColor: 'white',
                        border: 0.5,
                        '&:hover': {
                            backgroundColor: 'salmon',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FAFAFA',
                }}
            >
                {messages.map((message, index) => (
                    <Box
                        key={index}
                        sx={{
                            alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                            backgroundColor: message.sender === 'user' ? '#2E2E2E' : '#D8D8D8',
                            color: message.sender === 'user' ? 'white' : 'black',
                            borderRadius: '8px',
                            padding: 1,
                            maxWidth: '70%',
                            marginBottom: 1,
                        }}
                    >
                        <Typography sx={{ fontSize: '0.85rem', color: message.sender === 'bot' ? 'black' : 'white' }}>
                            {message.text}
                        </Typography>
                    </Box>
                ))}
                <div ref={endOfMessagesRef} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    padding: 1,
                    borderTop: '1px solid #e0e0e0',
                    backgroundColor: 'lightblue',
                }}
            >
                <TextField
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && inputMessage.trim() !== '') {
                            handleSend();
                        }
                    }}
                    placeholder="Shkruaj ketu..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                        marginRight: 1,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        '& .MuiInputBase-input': {
                            fontSize: '0.85rem',
                        },
                    }}
                    disabled={isLoading}
                    spellCheck={false}
                />
                <Button
                    onClick={handleSend}
                    variant="contained"
                    color="primary"
                    disabled={isLoading || inputMessage.trim() === ''}
                    sx={{ zIndex: 2000 }}
                >
                    {isLoading ? '...' : 'Dergoje'}
                </Button>
            </Box>
        </Box>
    );
};

const Bubble = ({ message }) => (
    <Box
        sx={{
            position: 'absolute',
            bottom: '80px',
            right: '10px',
            backgroundColor: '#FF6666',
            color: 'white',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            fontSize: '0.8rem',
            zIndex: 2000,
            animation: 'fadeIn 1s ease-in-out',
            '@keyframes fadeIn': {
                from: { opacity: 0 },
                to: { opacity: 1 }
            }
        }}
    >
        {message}
    </Box>
);

const AvatarBox = ({ avatarImagePath, toggleChatWindow, showBubble }) => {
    return (
        <Box
            onClick={toggleChatWindow}
            sx={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'fixed',
                bottom: '1rem',
                right: '1rem',
            }}
        >
            {showBubble && <Bubble message="Click me to chat!" />}
            <img
                src={avatarImagePath}
                alt="Avatari i Chatbotit"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        </Box>
    );
};

const ChatBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showBubble, setShowBubble] = useState(true);

    useEffect(() => {
        // const hasOpenedChatBefore = localStorage.getItem('hasOpenedChat');
        // if (!hasOpenedChatBefore) {
        //     setShowBubble(true);
        // }
        // Always show the bubble on reload
        setShowBubble(true);
    }, []);

    useEffect(() => {
        if (isOpen && messages.length === 0) {
            setMessages([{ text: "Mire se vini, une jam Gjyla nje Chatbot, si mund t'ju ndihmoj?", sender: 'bot' }]);
        }
    }, [isOpen, messages.length]);

    const handleSend = async () => {
        if (inputMessage.trim() !== '') {
            const userMessage = { text: inputMessage, sender: 'user' };
            setMessages([...messages, userMessage]);
            setInputMessage('');
            setIsLoading(true);

            try {
                const response = await fetch('https://www.ad-lux.com/api/chatbot-response', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: inputMessage }),
                });

                const responseText = await response.text();

                if (!response.ok) {
                    throw new Error(responseText || 'A network error occurred. Please try again later.');
                }

                const botMessage = { text: responseText, sender: 'bot' };
                setMessages(prev => [...prev, botMessage]);
            } catch (error) {
                const errorMessage = { text: error.message || 'An error occurred while processing the message...', sender: 'bot' };
                setMessages(prev => [...prev, errorMessage]);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const toggleChatWindow = useCallback(() => {
        setIsOpen(prev => !prev);

        // Hide the bubble once the chat window is opened
        if (!isOpen) {
            setShowBubble(false);
        }
    }, [isOpen]);

    const closeChatWindow = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <AvatarBox
                avatarImagePath='/images/frau-avatar.jpg'
                toggleChatWindow={toggleChatWindow}
                showBubble={showBubble}
            />
            <ChatWindow
                isOpen={isOpen}
                messages={messages}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                handleSend={handleSend}
                isLoading={isLoading}
                closeChatWindow={closeChatWindow}
            />
        </>
    );
};

export default ChatBot;