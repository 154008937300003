import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Date.now() / 1000;
};

const checkUsernameAvailability = async (username) => {
    try {
        const response = await axiosInstance.post('/api/check-username', { username });
        return response.data.available;
    } catch (error) {
        console.error('Error checking username availability:', error);
        return false;
    }
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const fetchUserProfile = async () => {
        try {
            const response = await axiosInstance.get('/api/user-profile');
            setUser(response.data);
            setIsAuthenticated(true);
        } catch (error) {
            console.error('Error fetching user profile:', error);
            setErrorMessage('Failed to load user profile');
            setIsAuthenticated(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !isTokenExpired(token)) {
            fetchUserProfile();
        } else {
            setLoading(false);
            logoutUser();
        }
    }, []);

    const forgotPassword = async (email) => {
        setErrorMessage('');
        setSuccessMessage('');
        if (!email) {
            setErrorMessage('Please enter your email address');
            return;
        }

        setLoading(true);
        try {
            await axiosInstance.post('/api/forgot-password', { email });
            setSuccessMessage('Password reset instructions sent to your email');
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Failed to send password reset email');
        } finally {
            setLoading(false);
        }
    };

    const registerUser = async ({ username, password, email }) => {
        setErrorMessage('');
        setSuccessMessage('');
        try {
            const isAvailable = await checkUsernameAvailability(username);
            if (!isAvailable) {
                setErrorMessage('Username is already taken, please choose a different one.');
                return;
            }

            const response = await axiosInstance.post('/api/register', { username, password, email });
            setSuccessMessage(response.data.message || 'Registration successful');
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Registration failed due to an unexpected error.');
        }
    };

    const loginUser = async (username, password) => {
        setErrorMessage('');
        setSuccessMessage('');
        setLoading(true);
        try {
            const response = await axiosInstance.post('/api/login', { username, password });
            const { access_token } = response.data;
    
            if (access_token && !isTokenExpired(access_token)) {
                localStorage.setItem('token', access_token);
                await fetchUserProfile(); // Fetch user profile after successful login
                setSuccessMessage('Login successful');
                setIsAuthenticated(true);
                return true;  // Success, so return true
            } else {
                setErrorMessage('Invalid or expired token received.');
                return false;  // Failed, so return false
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Login failed. Please check your credentials and try again.');
            return false;  // Login failed, return false
        } finally {
            setLoading(false);
        }
    };

    const logoutUser = () => {
        setUser(null);
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setSuccessMessage('Logged out successfully');
    };

    const updateUserProfile = async (updatedData) => {
        try {
            await axiosInstance.post('/api/update-profile', updatedData);
            setUser((prevUser) => ({ ...prevUser, ...updatedData }));
            setSuccessMessage('Profile updated successfully');
        } catch (error) {
            setErrorMessage('Failed to update profile');
        }
    };

    const updateAvatar = async (formData) => {
        try {
            const response = await axiosInstance.post('/api/update-profile-image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            
            // Assuming the server responds with the new image URL
            const newAvatarUrl = response.data.profilepic;
            
            setUser((prevUser) => ({
                ...prevUser,
                profilepic: newAvatarUrl
            }));
            setSuccessMessage('Profile image updated successfully');
        } catch (error) {
            console.error('Error updating profile image:', error);
            setErrorMessage('Failed to update profile image');
        }
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            user,
            registerUser,
            loginUser,
            logoutUser,
            checkUsernameAvailability,
            forgotPassword,
            updateUserProfile,
            updateAvatar,
            loading,
            errorMessage,
            successMessage,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

