import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { Box, Button, TextField, Typography, Avatar, CircularProgress } from '@mui/material';

function Profile() {
    const { user, updateUserProfile, updateAvatar, loading, errorMessage, successMessage } = useAuth();
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState(user?.email || '');

    const baseURL = 'https://www.ad-lux.com';

    const handlePasswordChange = async () => {
        await updateUserProfile({ newPassword });
        setNewPassword('');
    };

    const handleEmailChange = async () => {
        await updateUserProfile({ email: newEmail });
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('profileImage', file);
            await updateAvatar(formData);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '88vh',
                paddingTop: {
                    xs: '50px',  // Add padding-top only on extra small screens (e.g., mobile)
                    sm: '0px',    // No padding on small and larger screens
                },
                padding: 0.5,
                backgroundColor: '#f4f6f8'
            }}
        >
            <Typography variant="h4" gutterBottom>
                User Profile
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0,
                    padding: 0.5,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '400px',
                    width: '100%',
                    alignItems: 'center'
                }}
            >
                <Avatar
                    //src={user.profilepic} ? `${baseURL}${user.profilepic}` : '/images/default-profile.jpg'}
                    src={`${baseURL}${user.profilepic}` ? `${baseURL}${user.profilepic}` : '/images/default-profile.jpg'}
                    alt="Profile"
                    sx={{
                        width: 100,
                        height: 100,
                        marginBottom: 0.5,
                        objectFit: 'fill', // This will stretch the image
                    }}
                />
                <Button variant="contained" component="label" color="primary" sx={{ marginBottom: 2 }}>
                    Change Profile Image
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleImageChange}
                    />
                </Button>

                <Typography variant="h6" gutterBottom>
                    {user.username}
                </Typography>

                <TextField
                    label="Email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEmailChange}
                    fullWidth
                >
                    Update Email
                </Button>

                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePasswordChange}
                    fullWidth
                >
                    Update Password
                </Button>

                <Typography variant="body2" gutterBottom sx={{ marginTop: 0, color: 'black' }}>
                    Last Login: {new Date(user.lastlogin).toLocaleString()}
                </Typography>

                <Typography variant="body2" gutterBottom sx={{ marginTop: 0, color: 'black' }}>
                    Registered: {new Date(user.registered).toLocaleString()}
                </Typography>

                <Typography variant="body2" gutterBottom sx={{ marginTop: 0, color: 'black' }}>
                    Role: {user.rolle}
                </Typography>
            </Box>

            {successMessage && (
                <Typography color="primary" sx={{ marginTop: 2 }}>
                    {successMessage}
                </Typography>
            )}

            {errorMessage && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
}

export default Profile;