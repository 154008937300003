import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  CircularProgress, 
  Card, 
  CardContent, 
  Typography, 
  Chip 
} from '@mui/material';
import Papa from 'papaparse';

const FlightBooking = () => {
  const [airports, setAirports] = useState([]);
  const [searchTermFrom, setSearchTermFrom] = useState('');
  const [filteredAirportsFrom, setFilteredAirportsFrom] = useState([]);
  const [selectedAirportFrom, setSelectedAirportFrom] = useState('');

  const [searchTermTo, setSearchTermTo] = useState('');
  const [filteredAirportsTo, setFilteredAirportsTo] = useState([]);
  const [selectedAirportTo, setSelectedAirportTo] = useState('');

  const [departureDate, setDepartureDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [flights, setFlights] = useState([]);

  useEffect(() => {
    const loadCSV = async () => {
      const response = await fetch('/airport-codes_iata.csv');
      const csvText = await response.text();

      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const airportList = result.data.filter((row) => row.iata_code);
          setAirports(airportList);
        },
      });
    };
    loadCSV();
  }, []);

  useEffect(() => {
    if (searchTermFrom) {
      const filtered = airports.filter((airport) =>
        airport.name.toLowerCase().includes(searchTermFrom.toLowerCase()) ||
        airport.iata_code.toLowerCase().includes(searchTermFrom.toLowerCase())
      );
      setFilteredAirportsFrom(filtered);
    } else {
      setFilteredAirportsFrom([]);
    }
  }, [searchTermFrom, airports]);

  useEffect(() => {
    if (searchTermTo) {
      const filtered = airports.filter((airport) =>
        airport.name.toLowerCase().includes(searchTermTo.toLowerCase()) ||
        airport.iata_code.toLowerCase().includes(searchTermTo.toLowerCase())
      );
      setFilteredAirportsTo(filtered);
    } else {
      setFilteredAirportsTo([]);
    }
  }, [searchTermTo, airports]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedAirportFrom || !selectedAirportTo || !departureDate || !returnDate) {
      alert('Please fill in all fields.');
      return;
    }

    setIsLoading(true);

    const flightData = {
      airportFrom: selectedAirportFrom.iata_code,
      airportTo: selectedAirportTo.iata_code,
      departureDate,
      returnDate,
    };

    try {
      const response = await fetch('https://www.ad-lux.com/api/flights', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(flightData),
      });

      if (!response.ok) {
        throw new Error('Error occurred while fetching flights.');
      }

      const data = await response.json();
      console.log('Flight data:', data);

      setFlights(data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFlights = () => {
    return flights.map((flight, index) => (
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box>
              <Typography variant="h6" gutterBottom>
                {flight.airlines}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                CO2 Emissionen: {flight.co2_emissions}
              </Typography>
            </Box>
            <Typography variant="h6" color="primary">
              {flight.price}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box>
              <Typography variant="subtitle1">Abflug</Typography>
              <Typography variant="body1" color="textSecondary">{flight.departure_airport}</Typography>
              <Typography variant="body2" color="textSecondary">
                {flight.departure_time}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1">Ankunft</Typography>
              <Typography variant="body1" color="textSecondary">{flight.arrival_airport}</Typography>
              <Typography variant="body2" color="textSecondary">
                {flight.arrival_time}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Chip label={`Dauer: ${flight.duration}`} color="primary" sx={{ mr: 1 }} />
            <Chip label={flight.stops} color="secondary" />
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
              <Box sx={{ flexGrow: 1, minWidth: '200px', position: 'relative' }}>
                <TextField
                  label="Flughafen von"
                  value={selectedAirportFrom ? `${selectedAirportFrom.name} (${selectedAirportFrom.iata_code})` : searchTermFrom}
                  onChange={(e) => {
                    setSelectedAirportFrom('');
                    setSearchTermFrom(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder="Flughafenname oder IATA eingeben"
                />
                {filteredAirportsFrom.length > 0 && (
                  <Box sx={{ position: 'absolute', width: '100%', maxHeight: '200px', overflowY: 'auto', bgcolor: 'background.paper', boxShadow: 3, borderRadius: 1, zIndex: 1 }}>
                    {filteredAirportsFrom.map((airport) => (
                      <Box
                        key={airport.iata_code}
                        sx={{ p: 1, '&:hover': { bgcolor: 'action.hover' }, cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedAirportFrom(airport);
                          setSearchTermFrom('');
                          setFilteredAirportsFrom([]);
                        }}
                      >
                        {airport.name} ({airport.iata_code})
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>

              <Box sx={{ flexGrow: 1, minWidth: '200px', position: 'relative' }}>
                <TextField
                  label="Flughafen bis"
                  value={selectedAirportTo ? `${selectedAirportTo.name} (${selectedAirportTo.iata_code})` : searchTermTo}
                  onChange={(e) => {
                    setSelectedAirportTo('');
                    setSearchTermTo(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder="Flughafenname oder IATA eingeben"
                />
                {filteredAirportsTo.length > 0 && (
                  <Box sx={{ position: 'absolute', width: '100%', maxHeight: '200px', overflowY: 'auto', bgcolor: 'background.paper', boxShadow: 3, borderRadius: 1, zIndex: 1 }}>
                    {filteredAirportsTo.map((airport) => (
                      <Box
                        key={airport.iata_code}
                        sx={{ p: 1, '&:hover': { bgcolor: 'action.hover' }, cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedAirportTo(airport);
                          setSearchTermTo('');
                          setFilteredAirportsTo([]);
                        }}
                      >
                        {airport.name} ({airport.iata_code})
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>

              <Box sx={{ flexGrow: 1, minWidth: '200px' }}>
                <TextField
                  label="Abflugdatum"
                  type="date"
                  value={departureDate}
                  onChange={(e) => {
                    setDepartureDate(e.target.value);
                    if (returnDate && new Date(returnDate) < new Date(e.target.value)) {
                      setReturnDate('');
                    }
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Box>

              <Box sx={{ flexGrow: 1, minWidth: '200px' }}>
                <TextField
                  label="Rückflugdatum"
                  type="date"
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  inputProps={{ min: departureDate }}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Flüge suchen'}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Box>
        {flights.length > 0 ? (
          renderFlights()
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Keine Flüge gefunden
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FlightBooking;