import React from 'react';
import FlightBooking from '../FlightBooking';
import './Ballina.css';

function Ballina() {
  return (
    <FlightBooking />
  );
}

export default Ballina;