import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const { isAuthenticated, user, logoutUser } = useAuth();
  const navigate = useNavigate();

  const baseURL = 'https://www.ad-lux.com/';

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const toggleDropdown = () => {
    setDropdown((prevState) => !prevState); // Toggle dropdown menu visibility
  };

  const handleLogout = () => {
    logoutUser(); // Call the logout function from AuthContext
    setDropdown(false); // Close the dropdown after logging out
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);

    return () => {
      window.removeEventListener('resize', showButton); // Clean up
    };
  }, []);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            AD Lux
            <i className='fab fa-typo3' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/news' className='nav-links' onClick={closeMobileMenu}>
                News
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/ballina' className='nav-links' onClick={closeMobileMenu}>
                Store
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/genai' className='nav-links' onClick={closeMobileMenu}>
                GenAI
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/klubi' className='nav-links' onClick={closeMobileMenu}>
                YouAI
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/translate' className='nav-links' onClick={closeMobileMenu}>
                Translator
              </Link>
            </li>
            {/* Render "Log In" link only if the user is not authenticated */}
            {!isAuthenticated && (
              <li>
                <Link to='/login' className='nav-links-mobile' onClick={closeMobileMenu}>
                  Log In
                </Link>
              </li>
            )}
          </ul>

          {/* Check if user is authenticated */}
          {isAuthenticated && user ? (
            <div className='user-profile'>
              <img
                src={`${baseURL}${user.profilepic}` ? `${baseURL}${user.profilepic}` : '/images/default-profile.jpg'}
                alt='Profile Avatar'
                style={{
                  objectFit: 'fill',
                }}
                className='navbar-profilepic'
                onClick={toggleDropdown}
                zIndex='1999'
              />
              {/*<span className='user-name'>{user.username}</span>*/}
              {dropdown && (
                <div
                  className='dropdown-menu'
                  sx={{
                    border: '5 solid #fff'
                  }}
                >
                  <ul>
                    <li>
                      <div
                        className='dropdown-links'
                        onClick={() => {
                          setDropdown(false);
                          navigate('/profile'); // Navigate to /profile on click
                        }}
                      >
                        Profile
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className='dropdown-links' onClick={handleLogout}>
                        Log Out
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            button && (
              <Link to="/login" className='dropdown-button'>
                <Button buttonStyle='btn--outline'>Log In</Button>
              </Link>
            )
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;