import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import './GenAi.css';

function GenAi() {
    const [input, setInput] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastImages, setLastImages] = useState([]); // Store the last 10 images along with prompts
    const [errorMessage, setErrorMessage] = useState(''); // To display error message

    // List of unwanted words
    //const unwantedWords = ['lopa', 'lope', 'lopë', 'lop'];  // Replace with actual words
    const unwantedWords = [];  // Replace with actual words

    const handleSubmit = async () => {
        // Check for unwanted words
        if (containsUnwantedWords(input)) {
            setErrorMessage('Pershkrimi juaj përmban fjalë që nuk lejohen [diskriminime gjinore!].');
            return;  // Prevent the request from being sent
        }

        setIsLoading(true);
        setImageSrc(''); // Clear previous image
        setErrorMessage(''); // Clear any previous error messages

        try {
            const response = await fetch('https://www.ad-lux.com/api/image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ input }),  // Send 'input'
            });

            const data = await response.json();

            if (data.image) {
                const newImageSrc = `data:image/png;base64,${data.image}`;
                setImageSrc(newImageSrc);
                updateLastImages(newImageSrc, input); // Add the new image and prompt to the last images
            } else if (data.imageUrl) {
                const newImageSrc = data.imageUrl;
                setImageSrc(newImageSrc);
                updateLastImages(newImageSrc, input); // Add the new image and prompt to the last images
            } else {
                setImageSrc('Error fetching image');
            }
        } catch (error) {
            console.error('Error:', error);
            setImageSrc('Error fetching image');
        }

        setIsLoading(false);
    };

    // Function to check for unwanted words in the input
    const containsUnwantedWords = (text) => {
        const lowerCaseInput = text.toLowerCase();  // Case-insensitive check
        return unwantedWords.some(word => lowerCaseInput.includes(word.toLowerCase()));
    };

    // Function to update the lastImages array, keeping only the last 10 image/prompt pairs
    const updateLastImages = (newImageSrc, prompt) => {
        setLastImages((prevImages) => {
            const updatedImages = [{ image: newImageSrc, prompt }, ...prevImages];
            return updatedImages.slice(0, 20); // Keep only the last 10 images
        });
    };

    return (
        <Box
            className="genai-container"
            sx={{
                paddingTop: {
                    xs: '80px',  // Add padding-top only on extra small screens (e.g., mobile)
                    sm: '0px',    // No padding on small and larger screens
                },
                padding: 2,
            }}>
            <Typography variant="h6" component="h1" gutterBottom align='center'>
                Gjenerimi i fotografise nga pershkrimi
            </Typography>

            <Box
                className="tools_container"
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                    mb: 2,
                    padding: 2,
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                }}>
                <TextField
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Shkruaj nje pershkrim per nje fotografi..."
                    fullWidth
                    sx={{
                        flexGrow: 1,
                        backgroundColor: 'white',
                        input: { color: 'black' },
                    }}
                    error={!!errorMessage}  // Display error state
                    helperText={errorMessage}  // Display the error message
                />



                <Button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    {isLoading ? 'Fetching...' : 'Proceso'}
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '320px',
                    minHeight: '320px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                    textAlign: 'center',
                }}
            >
                {imageSrc ? (
                    <img
                        src={imageSrc}
                        alt="Fetched content"
                        style={{ maxWidth: '100%', maxHeight: '300px', minHeight: '300px', objectFit: 'contain' }}
                    />
                ) : (
                    <Typography variant="body1">
                        {isLoading ? 'Loading...' : 'No image to display.'}
                    </Typography>
                )}
            </Box>

            <Box
                className="last_images"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    padding: '0px',
                    paddingTop: { xs: '15px', sm: '30px' },
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    // Remove the scrollbar hiding styles
                    '&::-webkit-scrollbar': {
                        height: '8px',
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                }}
            >
                {lastImages.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'inline-block',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '8px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            width: '100px',
                            height: '100px',
                            flexShrink: 0, // Prevent shrinking
                            transition: 'transform 0.3s',
                            '@media (max-width: 600px)': {
                                width: '60px',
                                height: '60px',
                                padding: '4px',
                            },
                        }}
                        onClick={() => {
                            setImageSrc(item.image);
                            setInput(item.prompt);
                        }}
                    >
                        <img
                            src={item.image}
                            alt={`Generated content ${index + 1}`}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                                transition: 'transform 0.3s'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'rotate(15deg)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default GenAi;