import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import the custom useAuth hook

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const { forgotPassword, errorMessage, successMessage, loading } = useAuth(); // Destructure from useAuth
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        await forgotPassword(email); // Call the forgotPassword function from AuthContext
    };

    return (
        <Box
            className="forgot-password-container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '88vh',
                padding: 2,
                backgroundColor: '#f4f6f8',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Forgot Password
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
                    maxWidth: '400px',
                    width: '100%',
                }}
            >
                <TextField
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    error={!!errorMessage} // Show error if the email is invalid or missing
                    helperText={errorMessage || ''}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleForgotPassword}
                    disabled={loading} // Disable the button when loading
                >
                    {loading ? 'Sending...' : 'Send Reset Link'} {/* Show loading text */}
                </Button>
            </Box>

            {/* Show success message if email was sent */}
            {!!successMessage && (
                <Typography color="primary" sx={{ marginTop: 2 }}>
                    {successMessage}
                </Typography>
            )}

            {/* Show error message if something went wrong */}
            {!!errorMessage && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                    {errorMessage}
                </Typography>
            )}

            <Typography sx={{ marginTop: 2, color: 'black' }}>
                Remember your password? Click here to{' '}
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate('/login')} // Navigate to login page
                >
                    Login
                </Link>.
            </Typography>
        </Box>
    );
}

export default ForgotPassword;